<template>
  <v-form ref="form">
    <v-layout column>
      <v-layout align-center class="mx-4 my-4">
        <v-flex shrink>
          <v-switch
            v-model="value.mode"
            :disabled="disabled"
            :class="[
              disabled && !enabled && 'zg-text-control-enabled-disabled',
              'zg-text-control mt-0 pt-0',
            ]"
            color="expressive_green"
            label="Mam link do danych"
            value="link"
            hide-details
            :ripple="false"
            inset
          ></v-switch>
        </v-flex>
      </v-layout>
      <v-layout column v-if="enabled && !disabled">
        <v-flex :class="['xs12 sm6 mt-4']">
          <LabelTextField
            :rules="textRules"
            label="Wklej link do danych"
            required
            :disabled="disabled"
            :value.sync="value.link"
          >
          </LabelTextField>
        </v-flex>
        <PasswordField
          label="Podaj hasło do danych"
          :icon="false"
          :rules="noRules"
          :value.sync="value.link_password"
          :disabled="disabled"
          :required="false"
        >
        </PasswordField>
        <DefaultButton
          v-if="!disabled"
          :loading="loading"
          class="mx-4"
          primary
          @click.prevent="proceed"
          >Potwierdź</DefaultButton
        >
      </v-layout>
    </v-layout>
  </v-form>
</template>

<script>
import Validators from "@/mixins/Validators";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DataImportService from "@/services/dataimport.service";

export default {
  mixins: [ProxyCRUDMixin, Validators],
  props: {
    value: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noRules: [() => true],
    };
  },
  computed: {
    enabled() {
      return this.value.mode === "link";
    },
    disabled() {
      return !!this.value.state;
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    PasswordField: () => import("@/components/auth/PasswordField"),
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    proceed() {
      if (!this.validate()) {
        return;
      }

      const formData = new FormData();

      formData.append("link", this.value.link);
      formData.append("link_password", this.value.link_password ?? "");
      formData.append("status", this.value.status);

      this.beforeRequest();
      return DataImportService.updateDataImport(formData)
        .then(() => {
          this.handleSuccess("Wysłano dane pomyślnie.");
        })
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        })
        .finally(() => {
          this.$emit("refresh");
        });
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}

.zg-text-control-enabled-disabled::v-deep label {
  opacity: 0.6 !important;
}
</style>
